import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 4×6\\@80% 1RM`}</p>
    <p>{`Strict Pullups (CTB if possible) 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`12-9-6 reps each:`}</p>
    <p>{`Ring Muscle Ups`}</p>
    <p>{`Single Arm KB S2OH (per arm, 53/35)`}</p>
    <p>{`Jump Rope Singles x 10 (120/90/60)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free partner wod at 9:00 & 10:00am so come and
bring a friend!  The Color Run is also this Saturday so plan on leaving
the house early and parking on Market or a side street.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Granite Games Fall Throwdown is September 14th and registration
is now live!  Get your team of 3 together and register
at:`}</em></strong><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/2396#.XQlcOa2ZPys"
      }}>{`https://competitioncorner.net/events/2396#.XQlcOa2ZPys`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      